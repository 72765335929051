<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <div class="box">
                <div class="df-jc-s-b">
                    <div class="f16 c-1 bold df-ai-c"><span class="line"></span>钱包余额</div>
                    <div class="f14 c-1 pointer" @click="$oucy.go('/acc/billDetail')">账单明细>></div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-2 f14">总金额(元)</div>
                    <div>
                        <el-button type="primary" size="mini" @click="$oucy.go('/acc/topUp')">充值</el-button>
                    </div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-m bold f28">{{userEntity.userBalance}}(元)</div>
                    <div>
<!--                         <el-button type="" plain size="mini" @click="$oucy.go('/acc/withdrawal')">提现</el-button> -->
                        <el-button type="" plain size="mini" style="color: #EA8840">钱包余额仅用于平台消费，不可提现</el-button>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="df-jc-s-b">
                    <div class="f16 c-1 bold df-ai-c"><span class="line"></span>我的佣金</div>
                    <div class="f14 c-1 pointer" @click="$oucy.go('/acc/distribution/brokerage')">佣金明细>></div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-2 f14">总金额(元)</div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-m bold f28">{{userEntity.userTotalCommission}}</div>
                    <div>
                        <el-button type="primary" size="mini" @click="$oucy.go('/acc/distribution/GotoBalance')">转到余额</el-button>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="df-jc-s-b">
                    <div class="f16 c-1 bold df-ai-c"><span class="line"></span>我的积分</div>
                    <div class="f14 c-1 pointer" @click="$oucy.go('/acc/distribution/integral')">积分明细>></div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-2 f14">现有积分</div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-m bold f28">{{userEntity.userIntegral}}</div>
                    <div>
                        <!-- <el-button type="primary" size="mini">积分商城</el-button> -->
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="df-jc-s-b">
                    <div class="f16 c-1 bold df-ai-c"><span class="line"></span>我的优惠券</div>
                    <div class="f14 c-1 pointer" @click="$oucy.go('/acc/MyCoupon')">查看优惠券>></div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="c-2 f14">现有数量(张)</div>
                </div>
                <div class="df-jc-s-b m-t-15">
                    <div class="df">
                        <div class="text-center">
                            <div class="c-m bold f28">{{shoppingTicketTotal||0}}</div>
                            <div class="c-2 f12">购物券</div>
                        </div>
                    
                    <div class="text-center m-l-50">
                        <div class="c-m bold f28">{{cashTicketTotal||0}}</div>
                        <div class="c-2 f12">现金券</div>
                    </div>
                    <div class="text-center m-l-50">
                        <div class="c-m bold f28">{{logisticsTicketTotal||0}}</div>
                        <div class="c-2 f12">物流券</div>
                    </div>
                </div>
                <div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/receiveCoupon')">领券中心</el-button>
                </div>
            </div>
    </div>
    </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { userMaterial,membercarduser } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            userEntity: {},
            cashTicketTotal:null,
            logisticsTicketTotal:null,
            shoppingTicketTotal:null,
        }
    },
    mounted() {
        const me = this;
        userMaterial.queryUserMaterial().then(res => {
            me.userEntity = res;
            console.log(me.userEntity);
        });
        membercarduser.queryUserUserTicketNum().then(res=>{
            this.cashTicketTotal=res.cashTicketTotal
            this.logisticsTicketTotal=res.logisticsTicketTotal
            this.shoppingTicketTotal=res.shoppingTicketTotal
        })
    },
    methods: {}
}
</script>
<style scoped lang="less">
.line {
    width: 6px;
    height: 16px;
    background: #2090FF;
    border-radius: 1px;
    display: inline-block;
    margin-right: 4px;
}

.box {
    padding: 40px;
    background: #FAFAFA;
    border-radius: 2px;
}

.box+.box {
    margin-top: 20px;
}
</style>